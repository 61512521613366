.main_card {
    width: 400px;
    margin-top: 2rem;
    gap:20px;
    /* margin-bottom: 110px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    height: 400px;
    /* background-color:rgb(245, 232, 184) */
    /* border: 2px solid red; */
    /* border: 2px solid blue; */
}

.card_action {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    flex-direction: column;
    /* width:100%; */
    /* margin-top:1rem */
}

.cart_box {
    height: 300px;
    width: 330px;
    /* padding: 5px; */
    /* border: 2px solid black; */
    margin: auto;
}

.cart_img {
    height: 13rem;
    width: 100%;
    margin-top:0.1rem;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width: 768px) {
    .main_card {
        width: 330px;
        height: 380px;
        /* padding: 10px; */
    }

    .cart_box {
        height: 200px;
        width: 200px;
        padding: 15px;

    }

}

/* @media (min-width: 768px) and (max-width:1023px) {} */